/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"

import SEO from "../components/theme/header/seo"
import Layout from "../components/theme/layout"
import WishlistDrawer from "../components/drawers/wishlist-drawer"

// ======================
//        COMPONENT
// ======================

const title = "Wishlist"

const WishlistPage = ({ pageContext, location }) => {
  return (
    <Layout pathContext={pageContext} location={location}>
      <SEO title={title} location={location} yoast={{}} />
      <WishlistDrawer isWishlistPage location={location} />
    </Layout>
  )
}

export default WishlistPage
